import React, { PureComponent } from "react"

/* Count Up */
import CountUp from 'react-countup'
import { Odometer } from 'odometer_countup'

/* Components */
import Picture from "./Picture"

/* Constants */
import { env } from "../constants"


/* Component Jackpot */
class Jackpot extends PureComponent {

    constructor() {
        super()

        this.state = {
            start: 0,
            end: 0
        }
    }

    componentDidMount = () => {

        const { jackpot } = this.props

        if (jackpot) {

            let start = 0
            let end = jackpot

            if (parseFloat(jackpot) >= 1000000) {
                end = 999999.99
            }

            this.setState({ start, end })
        }

    }

    componentDidUpdate = (prevProps) => {

        const { jackpot } = this.props

        if (jackpot !== undefined && jackpot !== null) {
            if (parseFloat(jackpot) !== parseFloat(prevProps.jackpot)) {

                let start = 0
                let end = parseFloat(jackpot)

                if (prevProps.jackpot) {
                    start = parseFloat(prevProps.jackpot)
                }

                if (parseFloat(jackpot) >= 1000000) {
                    end = 999999.99
                }

                this.setState({ start, end })
            }
        }
    }

    render = () => {

        const { start, end } = this.state
        const { jackpot } = this.props

        const odotmeterDuration = start === 0 ? 0.5 : 10
        const countupDuration = start === 0 ? 0.5 : 3

        return (
            <div className="jackpot">

                <div className="jackpot-title">JACKPOT</div>

                {parseFloat(jackpot) > 0
                    ? <CountUp
                        start={start}
                        end={end}
                        decimals={2}
                        duration={countupDuration}
                        decimal="."
                        separator=","
                        preserveValue={true}
                        plugin={new Odometer({ duration: odotmeterDuration, lastDigitDelay: 0 })}
                    >
                        {({ countUpRef }) => <div className="jackpot-text" ref={countUpRef} />}
                    </CountUp>
                    : <div className="jackpot-text">0.00</div>
                }

                <div className="jackpot-coin">
                    <Picture src={`${env.cpoint}/jackpot/dollar.png`} alt="Dollar" />
                </div>

                <div className="jackpot-coins">
                    <Picture src={`${env.cpoint}/jackpot/dollars.png`} alt="Dollars" />
                </div>

                <div className="jackpot-money">
                    <Picture src={`${env.cpoint}/jackpot/money.png`} alt="Money" />
                </div>

            </div>
        )
    }

}

export default Jackpot